// We need to import the CSS so that webpack will load it.
// The MiniCssExtractPlugin is used to separate it out into
// its own CSS file.
// import {css} from "../css/app.scss"
import { Socket } from "phoenix"
import { LiveSocket } from "phoenix_live_view"
// import { M } from "materialize-css"
// import "./matrix_edit"

// import "../css/app.css"
// import "../css/live_view.css"
import "../css/styleriser_tools.css"

//Expand Menus. Example in users/index
document.addEventListener('DOMContentLoaded', function () {
  var elems = document.querySelectorAll('.collapsible.expandable');
  var collapsableInstances = M.Collapsible.init(elems, {
    accordion: false,
    inDuration: 500,
    outDuration: 500
  });
  var selectableInstances = M.FormSelect.init($('select'), {})
});


//Hooks for phx-hook live_view attribute.
let Hooks = {}
// Hooks.selectColor = { //selectColor used for color_editor
//   id() { return this.el.dataset.colorid },
//   mounted() {
//     this.colorid = this.id()
//     this.el.addEventListener("click", e => { //when a color is clicked, change its style to make it obvious.
//       var style = this.el.getAttribute("style");
//       if(style.indexOf("padding:3px 3px") == -1) {
//         style = style.concat("padding:3px 3px;");
//         style = style.concat("background-clip:content-box;");
//       } else {
//         style = style.replace("padding:3px 3px;","");
//         style = style.replace("background-clip:content-box;", "");
//       }
//       //console.log(style);
//       this.el.setAttribute("style", style);
//       this.pushEvent("show", {"id" : this.colorid});
//     })
//   },
// }
/*
  commitPreviewChanges used for season_editor
  When the commit button on season_editor is clicked,
  this hook gets the elements found in the season_preview
  section and saves their data-id and data-src in a tuple
  and stores them in a list. This list is pushed to the
  server using the pushEvent callback, passing the list
  to the server.
*/
Hooks.commitPreviewChanges = {
  mounted() {
    this.el.addEventListener("click", e => {
      this.el.setAttribute("disabled", true); //disable the button
      var children = document.getElementById('season_preview').children; //get the colors in season_preview
      var dataList = [];
      // var child;
      for (let i = 0; i < children.length; ++i) {
        console.log(children[i].dataset);
        dataList.push({ "action_data": children[i].dataset, "order": (i + 1) })//add the datasets (id and src) to the list. Order starts at 1
      }
      // for(child of children) {
      //   dataList.push(child.dataset); //add the datasets (id and src) to the list
      // }
      this.pushEvent("commit", { "action_data": dataList, "id": "false" });//push event and data to server
    })
    //handle event from server to reenable the button. This event occurs when the
    //user cancels the commit. If the user goes through with it, the page reloads
    //and the button is by default enabled.
    this.handleEvent("enableButton", ({ }) => this.el.removeAttribute("disabled"));
  },
  updated() {
    //after the server updates, the static HTML reverts to what it was last time
    //the server updated the page. This means the button is enabled again before
    //the user confirms or cancels the commit. This event handler disables
    //the button after the server updates the page.
    this.handleEvent("disableButton", ({ }) => this.el.setAttribute("disabled", true));
  }
}

let csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content")
let liveSocket = new LiveSocket("/live", Socket, { params: { _csrf_token: csrfToken }, hooks: Hooks })

window.$ = window.jQuery = require("jquery");

liveSocket.connect()
// window.liveSocket = liveSocket
// webpack automatically bundles all modules in your
// entry points. Those entry points can be configured
// in "webpack.config.js".
//
// Import deps with the dep name or local files with a relative path, for example:
//
//     import {Socket} from "phoenix"
//     import socket from "./socket"
//
import "phoenix_html"

// Color Matrix edit functions

window.rangeValueL = function rangeValueL() {
  var elemL = document.querySelector('#color_l');
  var newValue = elemL.value;
  var target = document.querySelector('#l_value');
  target.innerHTML = "L: " + newValue;
  update_color_preview()
}

window.rangeValueA = function rangeValueA() {
  var elemA = document.querySelector('#color_a');
  var newValue = elemA.value;
  var target = document.querySelector('#a_value');
  target.innerHTML = "A: " + newValue;
  update_color_preview()
}

window.rangeValueB = function rangeValueB() {
  var elemB = document.querySelector('#color_b');
  var newValue = elemB.value;
  var target = document.querySelector('#b_value');
  target.innerHTML = "B: " + newValue;
  update_color_preview()
}

window.transformXyz = function transformXyz(val, epsilon) {
  var val3 = val * val * val

  return (val3 > epsilon) ? val3 : ((val - 16 / 116) / 7.787)
}

window.transformRgb = function transformRgb(val) {
  var v = (val > 0.0031308) ? (1.055 * Math.pow(val, 1 / 2.4) - 0.055) : (12.92 * val);
  return Math.round(v * 255)
}

window.update_color_preview = function update_color_preview() {
  var elemL = document.querySelector('#color_l'),
    elemA = document.querySelector('#color_a'),
    elemB = document.querySelector('#color_b'),
    prvw_style = document.getElementById("color_preview").style;

  var x, y, z, r, g, b,
    l = parseInt(elemL.value, 10),
    a = parseInt(elemA.value, 10),
    b = parseInt(elemB.value, 10),
    // set in root.html
    epsilon = window.xyzEpsilon,
    wr_x = window.wrX,
    wr_y = window.wrY,
    wr_z = window.wrZ;

  y = (l + 16) / 116.0;
  x = y + (a / 500.0);
  z = y - (b / 200.0);

  x = (wr_x * transformXyz(x, epsilon))
  y = (wr_y * transformXyz(y, epsilon))
  z = (wr_z * transformXyz(z, epsilon))

  x /= 100
  y /= 100
  z /= 100

  r = (x * 3.2406) + (y * -1.5372) + (z * -0.4986);
  g = (x * -0.9689) + (y * 1.8758) + (z * 0.0415);
  b = (x * 0.0557) + (y * -0.2040) + (z * 1.0570);

  r = transformRgb(r);
  g = transformRgb(g);
  b = transformRgb(b);

  r = (r < 0) ? 0 : ((r > 255) ? 255 : r)
  g = (g < 0) ? 0 : ((g > 255) ? 255 : g)
  b = (b < 0) ? 0 : ((b > 255) ? 255 : b)

  // hex conversion
  r = parseInt(r, 10).toString(16).padStart(2, "0")
  g = parseInt(g, 10).toString(16).padStart(2, "0")
  b = parseInt(b, 10).toString(16).padStart(2, "0")

  let hex = "#" + r + g + b;

  prvw_style.backgroundColor = hex;
  var hexnode = document.getElementById("hex_color");
  if (hexnode !== null) {
    hexnode.innerHTML = "Hex: " + hex
  }
}

window.clearPlotForm = function clearPlotForm() {
  // Reset LAB values
  document.getElementById('color_l').value = 0
  document.getElementById('color_a').value = 0
  document.getElementById('color_b').value = 0
  document.getElementById('l_value').innerHTML = 'L: 0'
  document.getElementById('a_value').innerHTML = 'A: 0'
  document.getElementById('b_value').innerHTML = 'B: 0'

  // Reset Seasons
  document.getElementById('color_matrix_season').selectedIndex = -1

  // Reset Temps
  document.getElementById('color_matrix_temp').selectedIndex = -1

  // Reset Name
  document.getElementById('color_name').value = ""

  // Reset k
  document.getElementById('k').value = 0

  // Reset color preview + hex_color
  update_color_preview()
}
